var plastic = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#1085FF",
    "activityBar.background": "#21252B",
    "activityBar.border": "#0D1117",
    "activityBar.foreground": "#C6CCD7",
    "activityBar.inactiveForeground": "#5F6672",
    "activityBarBadge.background": "#E06C75",
    "activityBarBadge.foreground": "#ffffff",
    "breadcrumb.focusForeground": "#C6CCD7",
    "breadcrumb.foreground": "#5F6672",
    "button.background": "#E06C75",
    "button.foreground": "#ffffff",
    "button.hoverBackground": "#E48189",
    "button.secondaryBackground": "#0D1117",
    "button.secondaryForeground": "#ffffff",
    "checkbox.background": "#61AFEF",
    "checkbox.foreground": "#ffffff",
    "contrastBorder": "#0D1117",
    "debugToolBar.background": "#181A1F",
    "diffEditor.border": "#0D1117",
    "diffEditor.diagonalFill": "#0D1117",
    "diffEditor.insertedLineBackground": "#CBF6AC0D",
    "diffEditor.insertedTextBackground": "#CBF6AC1A",
    "diffEditor.removedLineBackground": "#FF9FA80D",
    "diffEditor.removedTextBackground": "#FF9FA81A",
    "dropdown.background": "#181A1F",
    "dropdown.border": "#0D1117",
    "editor.background": "#21252B",
    "editor.findMatchBackground": "#00000000",
    "editor.findMatchBorder": "#1085FF",
    "editor.findMatchHighlightBackground": "#00000000",
    "editor.findMatchHighlightBorder": "#C6CCD7",
    "editor.foreground": "#A9B2C3",
    "editor.lineHighlightBackground": "#A9B2C31A",
    "editor.lineHighlightBorder": "#00000000",
    "editor.linkedEditingBackground": "#0D1117",
    "editor.rangeHighlightBorder": "#C6CCD7",
    "editor.selectionBackground": "#A9B2C333",
    "editor.selectionHighlightBackground": "#A9B2C31A",
    "editor.selectionHighlightBorder": "#C6CCD7",
    "editor.wordHighlightBackground": "#00000000",
    "editor.wordHighlightBorder": "#1085FF",
    "editor.wordHighlightStrongBackground": "#00000000",
    "editor.wordHighlightStrongBorder": "#1085FF",
    "editorBracketHighlight.foreground1": "#A9B2C3",
    "editorBracketHighlight.foreground2": "#61AFEF",
    "editorBracketHighlight.foreground3": "#E5C07B",
    "editorBracketHighlight.foreground4": "#E06C75",
    "editorBracketHighlight.foreground5": "#98C379",
    "editorBracketHighlight.foreground6": "#B57EDC",
    "editorBracketHighlight.unexpectedBracket.foreground": "#D74E42",
    "editorBracketMatch.background": "#00000000",
    "editorBracketMatch.border": "#1085FF",
    "editorCursor.foreground": "#A9B2C3",
    "editorError.foreground": "#D74E42",
    "editorGroup.border": "#0D1117",
    "editorGroup.emptyBackground": "#181A1F",
    "editorGroupHeader.tabsBackground": "#181A1F",
    "editorGutter.addedBackground": "#98C379",
    "editorGutter.deletedBackground": "#E06C75",
    "editorGutter.modifiedBackground": "#D19A66",
    "editorHoverWidget.background": "#181A1F",
    "editorHoverWidget.border": "#1085FF",
    "editorIndentGuide.activeBackground": "#A9B2C333",
    "editorIndentGuide.background": "#0D1117",
    "editorInfo.foreground": "#1085FF",
    "editorInlayHint.background": "#00000000",
    "editorInlayHint.foreground": "#5F6672",
    "editorLightBulb.foreground": "#E9D16C",
    "editorLightBulbAutoFix.foreground": "#1085FF",
    "editorLineNumber.activeForeground": "#C6CCD7",
    "editorLineNumber.foreground": "#5F6672",
    "editorOverviewRuler.addedForeground": "#98C379",
    "editorOverviewRuler.border": "#0D1117",
    "editorOverviewRuler.deletedForeground": "#E06C75",
    "editorOverviewRuler.errorForeground": "#D74E42",
    "editorOverviewRuler.findMatchForeground": "#1085FF",
    "editorOverviewRuler.infoForeground": "#1085FF",
    "editorOverviewRuler.modifiedForeground": "#D19A66",
    "editorOverviewRuler.warningForeground": "#E9D16C",
    "editorRuler.foreground": "#0D1117",
    "editorStickyScroll.background": "#181A1F",
    "editorStickyScrollHover.background": "#21252B",
    "editorSuggestWidget.background": "#181A1F",
    "editorSuggestWidget.border": "#1085FF",
    "editorSuggestWidget.selectedBackground": "#A9B2C31A",
    "editorWarning.foreground": "#E9D16C",
    "editorWhitespace.foreground": "#A9B2C31A",
    "editorWidget.background": "#181A1F",
    "errorForeground": "#D74E42",
    "focusBorder": "#1085FF",
    "gitDecoration.deletedResourceForeground": "#E06C75",
    "gitDecoration.ignoredResourceForeground": "#5F6672",
    "gitDecoration.modifiedResourceForeground": "#D19A66",
    "gitDecoration.untrackedResourceForeground": "#98C379",
    "input.background": "#0D1117",
    "inputOption.activeBorder": "#1085FF",
    "inputValidation.errorBackground": "#D74E42",
    "inputValidation.errorBorder": "#D74E42",
    "inputValidation.infoBackground": "#1085FF",
    "inputValidation.infoBorder": "#1085FF",
    "inputValidation.infoForeground": "#0D1117",
    "inputValidation.warningBackground": "#E9D16C",
    "inputValidation.warningBorder": "#E9D16C",
    "inputValidation.warningForeground": "#0D1117",
    "list.activeSelectionBackground": "#A9B2C333",
    "list.activeSelectionForeground": "#ffffff",
    "list.errorForeground": "#D74E42",
    "list.focusBackground": "#A9B2C333",
    "list.hoverBackground": "#A9B2C31A",
    "list.inactiveFocusOutline": "#5F6672",
    "list.inactiveSelectionBackground": "#A9B2C333",
    "list.inactiveSelectionForeground": "#C6CCD7",
    "list.warningForeground": "#E9D16C",
    "minimap.findMatchHighlight": "#1085FF",
    "minimap.selectionHighlight": "#C6CCD7",
    "minimapGutter.addedBackground": "#98C379",
    "minimapGutter.deletedBackground": "#E06C75",
    "minimapGutter.modifiedBackground": "#D19A66",
    "notificationCenter.border": "#0D1117",
    "notificationCenterHeader.background": "#181A1F",
    "notificationToast.border": "#0D1117",
    "notifications.background": "#181A1F",
    "notifications.border": "#0D1117",
    "panel.background": "#181A1F",
    "panel.border": "#0D1117",
    "panelTitle.inactiveForeground": "#5F6672",
    "peekView.border": "#1085FF",
    "peekViewEditor.background": "#181A1F",
    "peekViewEditor.matchHighlightBackground": "#A9B2C333",
    "peekViewResult.background": "#181A1F",
    "peekViewResult.matchHighlightBackground": "#A9B2C333",
    "peekViewResult.selectionBackground": "#A9B2C31A",
    "peekViewResult.selectionForeground": "#C6CCD7",
    "peekViewTitle.background": "#181A1F",
    "sash.hoverBorder": "#A9B2C333",
    "scrollbar.shadow": "#00000000",
    "scrollbarSlider.activeBackground": "#A9B2C333",
    "scrollbarSlider.background": "#A9B2C31A",
    "scrollbarSlider.hoverBackground": "#A9B2C333",
    "sideBar.background": "#181A1F",
    "sideBar.border": "#0D1117",
    "sideBar.foreground": "#C6CCD7",
    "sideBarSectionHeader.background": "#21252B",
    "statusBar.background": "#21252B",
    "statusBar.border": "#0D1117",
    "statusBar.debuggingBackground": "#21252B",
    "statusBar.debuggingBorder": "#56B6C2",
    "statusBar.debuggingForeground": "#A9B2C3",
    "statusBar.focusBorder": "#A9B2C3",
    "statusBar.foreground": "#A9B2C3",
    "statusBar.noFolderBackground": "#181A1F",
    "statusBarItem.activeBackground": "#0D1117",
    "statusBarItem.errorBackground": "#21252B",
    "statusBarItem.errorForeground": "#D74E42",
    "statusBarItem.focusBorder": "#A9B2C3",
    "statusBarItem.hoverBackground": "#181A1F",
    "statusBarItem.hoverForeground": "#A9B2C3",
    "statusBarItem.remoteBackground": "#21252B",
    "statusBarItem.remoteForeground": "#B57EDC",
    "statusBarItem.warningBackground": "#21252B",
    "statusBarItem.warningForeground": "#E9D16C",
    "tab.activeBackground": "#21252B",
    "tab.activeBorderTop": "#1085FF",
    "tab.activeForeground": "#C6CCD7",
    "tab.border": "#0D1117",
    "tab.inactiveBackground": "#181A1F",
    "tab.inactiveForeground": "#5F6672",
    "tab.lastPinnedBorder": "#A9B2C333",
    "terminal.ansiBlack": "#5F6672",
    "terminal.ansiBlue": "#61AFEF",
    "terminal.ansiBrightBlack": "#5F6672",
    "terminal.ansiBrightBlue": "#61AFEF",
    "terminal.ansiBrightCyan": "#56B6C2",
    "terminal.ansiBrightGreen": "#98C379",
    "terminal.ansiBrightMagenta": "#B57EDC",
    "terminal.ansiBrightRed": "#E06C75",
    "terminal.ansiBrightWhite": "#A9B2C3",
    "terminal.ansiBrightYellow": "#E5C07B",
    "terminal.ansiCyan": "#56B6C2",
    "terminal.ansiGreen": "#98C379",
    "terminal.ansiMagenta": "#B57EDC",
    "terminal.ansiRed": "#E06C75",
    "terminal.ansiWhite": "#A9B2C3",
    "terminal.ansiYellow": "#E5C07B",
    "terminal.foreground": "#A9B2C3",
    "titleBar.activeBackground": "#21252B",
    "titleBar.activeForeground": "#C6CCD7",
    "titleBar.border": "#0D1117",
    "titleBar.inactiveBackground": "#21252B",
    "titleBar.inactiveForeground": "#5F6672",
    "toolbar.hoverBackground": "#A9B2C333",
    "widget.shadow": "#00000000"
  },
  "displayName": "Plastic",
  "name": "plastic",
  "semanticHighlighting": true,
  "semanticTokenColors": {},
  "tokenColors": [
    {
      "scope": [
        "comment",
        "punctuation.definition.comment",
        "source.diff"
      ],
      "settings": {
        "foreground": "#5F6672"
      }
    },
    {
      "scope": [
        "entity.name.function",
        "support.function",
        "meta.diff.range",
        "punctuation.definition.range.diff"
      ],
      "settings": {
        "foreground": "#B57EDC"
      }
    },
    {
      "scope": [
        "keyword",
        "punctuation.definition.keyword",
        "variable.language",
        "markup.deleted",
        "meta.diff.header.from-file",
        "punctuation.definition.deleted",
        "punctuation.definition.from-file.diff"
      ],
      "settings": {
        "foreground": "#E06C75"
      }
    },
    {
      "scope": [
        "constant",
        "support.constant"
      ],
      "settings": {
        "foreground": "#56B6C2"
      }
    },
    {
      "scope": [
        "storage",
        "support.class",
        "entity.name.namespace",
        "meta.diff.header"
      ],
      "settings": {
        "foreground": "#61AFEF"
      }
    },
    {
      "scope": [
        "markup.inline.raw.string",
        "string",
        "markup.inserted",
        "punctuation.definition.inserted",
        "meta.diff.header.to-file",
        "punctuation.definition.to-file.diff"
      ],
      "settings": {
        "foreground": "#98C379"
      }
    },
    {
      "scope": [
        "entity.name.section",
        "entity.name.tag",
        "entity.name.type",
        "support.type"
      ],
      "settings": {
        "foreground": "#E5C07B"
      }
    },
    {
      "scope": [
        "support.type.property-name",
        "support.variable",
        "variable"
      ],
      "settings": {
        "foreground": "#C6CCD7"
      }
    },
    {
      "scope": [
        "entity.other",
        "punctuation.definition.entity",
        "support.other"
      ],
      "settings": {
        "foreground": "#D19A66"
      }
    },
    {
      "scope": [
        "meta.brace",
        "punctuation"
      ],
      "settings": {
        "foreground": "#A9B2C3"
      }
    },
    {
      "scope": [
        "markup.bold",
        "punctuation.definition.bold",
        "entity.other.attribute-name.id"
      ],
      "settings": {
        "fontStyle": "bold"
      }
    },
    {
      "scope": [
        "comment",
        "markup.italic",
        "punctuation.definition.italic"
      ],
      "settings": {
        "fontStyle": "italic"
      }
    }
  ],
  "type": "dark"
});

export { plastic as default };
